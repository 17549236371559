<template>
  <tr>
    <td>{{ rowTitle }}</td>
    <td v-for="ofertaItem in ofertasComparacao" :key="ofertaItem.oferta.id" class="text-center">
      <template v-if="preco(ofertaItem.oferta)">
        {{ preco(ofertaItem.oferta) | currency }}
      </template>
      <template v-else>
        Sob consulta
      </template>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    ofertasComparacao: {
      type: Array,
      required: true,
    },
    rowTitle: {
      type: String,
      default: 'Preço à vista',
    },
    isAVista: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    preco() {
      return (oferta) => {
        if (this.isAVista) {
          return this.precoAVista(oferta);
        }
        return this.precoAPrazo(oferta);
      };
    },
    precoAVista() {
      return oferta => (oferta.promocaoAtiva
        ? oferta.precoPromocionalAVista : oferta.precoAVista);
    },
    precoAPrazo() {
      return oferta => (oferta.promocaoAtiva
        ? oferta.precoPromocionalAPrazo : oferta.precoAPrazo);
    },
  },
};
</script>
