<template>
  <tr class="non-selectable-tr">
    <td style="min-width: 130px"></td>
    <template v-for="ofertaItem in ofertasComparacao">
      <td :key="ofertaItem.oferta.id">
        <px-oferta-foto max-height="200"
                        min-height="200"
                        max-width="200"
                        img-class="mt-5 mb-3"
                        align="center"
                        :foto="ofertaItem.oferta.foto"/>
        <div v-if="isLogged"
             class="d-flex align-center flex-column justify-center mt-3 mb-3">
          <div class="mb-3">
            <a class="caption" @click="removerProduto(ofertaItem.oferta)">
              <v-icon small color="primary">mdi-close</v-icon>
              Remover produto
            </a>
          </div>
          <px-oferta-dialog :id-oferta="ofertaItem.oferta.id">
            <template v-slot:trigger="{ on }">
              <v-btn small
                     text
                     color="primary"
                     class="normal-btn-text rounded-tag-small"
                     v-on="on">
                <v-icon small>mdi-cart</v-icon>
                Adicionar ao carrinho
              </v-btn>
            </template>
          </px-oferta-dialog>
        </div>
      </td>
    </template>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { REMOVE_OFERTA_ACT } from '@/store/compare/actions.type';

export default {
  props: {
    ofertasComparacao: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
  methods: {
    ...mapActions('compare', {
      removeOferta: REMOVE_OFERTA_ACT,
    }),
    removerProduto(oferta) {
      this.removeOferta({
        id: oferta.id,
      });
      this.$emit('change');
    },
  },
};
</script>
