<template>
  <tr>
    <td>
      {{ rowTitle }}
      <slot name="column"/>
    </td>
    <td v-for="ofertaItem in ofertasComparacao" :key="ofertaItem.oferta.id"
        class="text-center">
      <v-icon :color="color(ofertaItem.oferta)">{{ icon(ofertaItem.oferta) }}</v-icon>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    ofertasComparacao: {
      type: Array,
      required: true,
    },
    flagValue: {
      type: String,
      required: true,
    },
    rowTitle: {
      type: String,
    },
  },
  computed: {
    icon() {
      return (oferta) => {
        if (oferta[this.flagValue]) {
          return 'mdi-check';
        }
        return 'mdi-close';
      };
    },
    color() {
      return (oferta) => {
        if (oferta[this.flagValue]) {
          return 'success';
        }
        return 'error';
      };
    },
  },
};
</script>
