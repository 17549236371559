<template>
  <tr>
    <td>Frete</td>
    <td v-for="ofertaItem in ofertasComparacao" :key="ofertaItem.id"
        class="text-center">
      <px-oferta-info-fretes class="mt-5"
                             class-oferta-gratis="body-2"
                             frase-oferta-frete-gratis="Frete grátis a partir de"
                             :show-icon="false"
                             :oferta="ofertaItem.oferta"
                             :comprador="comprador"
                             @loaded="onLoadFrete($event, ofertaItem)"/>
    </td>
  </tr>
</template>

<script>
import OfertaInfoFretes from '../ofertas/oferta/OfertaInfoFretes.vue';

export default {
  props: {
    ofertasComparacao: {
      type: Array,
      required: true,
    },
    comprador: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onLoadFrete(frete, oferta) {
      this.$emit('loaded', { id: oferta.oferta.id, ...frete });
    },
  },
  components: {
    pxOfertaInfoFretes: OfertaInfoFretes,
  },
};
</script>
